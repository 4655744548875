(function($, document, window) {




    if ($(".related-exsists")[0]){
        // Do something if class exists
        $(".related-doesnt-exsist").hide();
    } else {
        // Do something if class does not exist
    
    }

   
    $( ".page-section:not([id^='page-footer'])" ).first().attr( "id", "page-main" );


    var $body = $('body');

    // var mac = $.browser.mac();  
    // var win = $.browser.win();   

    // if (mac) $('html').addClass('mac');
    // if (win) $('html').addClass('windows');

    $(window).on('load', function(){
        $(".crm-gencontact").removeAttr('style');
    });
    

    $(window).on('load resize', function () {
        $('#back-rfp').headroom('destroy');
        $('#back-rfp').headroom({
            offset: window.innerHeight - 100,
            tolerance: 5
        });

        $('#page-header').headroom('destroy');
        
        $('#page-header').headroom({
            offset: window.innerHeight - 150,
            tolerance: 5
        });

        // vertical title
        $('section > .inner > h2').each(function () {
            var height = $(this).height();
            var parent = $(this).parent();
            
            if (parent.height() < height) {
				//parent.height(height);
				parent.css('min-height', height + 'px');
            }
        });
    });


    var verticalTabs = $('.vertical-tabs');

    if (verticalTabs.length > 0) {        


            var positions = verticalTabs.find('li');
            positions.on('click', function () {
                if (verticalTabs.hasClass('manual-mode')) {
                    $(this).toggleClass("active");
                }
                else {
                    positions.each(function () {
                        $(this).removeClass("active");
                    });    
                    $(this).addClass("active");
                }
            });
    }



    // remove outline
	var styleText = '::-moz-focus-inner{border:0 !important;}:focus{outline: none !important;';
	var unfocus_style = document.createElement('style');

	window.unfocus = function(){
		document.getElementsByTagName('head')[0].appendChild(unfocus_style);

		document.addEventListener('mousedown', function(){
			unfocus_style.innerHTML = styleText+'}';
		});
		document.addEventListener('keydown', function(){
			unfocus_style.innerHTML = '';
		});
	};

	unfocus.style = function(style){
		styleText += style;
	};

	unfocus();


    $(document).ready(() => {
        let url = location.href.replace(/\/$/, "");

   
        if (location.hash) {

            
            $('html, body').animate({
                scrollTop: $('#myTab').offset().top - 220
            }, 1000, 'swing');


            const hash = url.split("#");
            $('#myTab a[href="#'+hash[1]+'"]').tab("show");
            url = location.href.replace(/\/#/, "#");
            //url = location.href
            history.replaceState(null, null, url);
            setTimeout(() => {
                $(window).scrollTop(0);
            }, 400);
        } 
   
        $('a[data-bs-toggle="tab"]').on("click", function() {
            let newUrl;
            const hash = $(this).attr("href");
            
            if(hash == "#tab-0") {
                newUrl = url.split("#")[0];
            } else {
                newUrl = url.split("#")[0] + hash;
            }
            // newUrl += "/";
            history.replaceState(null, null, newUrl);
        });
    });


    $('.efw-button').on('click', function () {
        $('.efw-body').toggleClass('open');
        $('.efw-button .meta').toggleClass('open');
    });


    // audio
    $('.media-section .controls button').on('click', function () {
        var $button = $(this);
        var $section = $button.parents('.media-section');
        var audio = $section.find('audio')[0];

        if ($button.hasClass('play')) {
            $button.removeClass('play');
            $button.addClass('pause');
            audio.play();

        } else {
            $button.removeClass('pause')
            $button.addClass('play');
            audio.pause();
        }
    });

    $('.media-section audio').on('timeupdate', function () {
        var $progress = $(this).siblings('.poster').find('.controls progress');
        $progress.attr('value', this.currentTime / this.duration * 100);
    });

    

    setBackgroundImage($('.background-image'));

    $('a.close').on('click', function(){
        $(this).closest('.collapse').removeClass('show');
    });
    $('a.background-image').on('click', function(){
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".people-section").offset().top-100
        }, 1000);
    });

    
    // Menu
    var $menuButton =  $('.nav-button');
    $menuButton.on('click', function () {
        $body.toggleClass('menu-open');

        if ($body.hasClass('menu-open')) {

        }
    });

    $('#page-nav').on('click', function () {
        $body.removeClass('menu-open');
    });
     
    $('#page-nav .inner').click(function (event) {
        event.stopPropagation();
    });

    $('#main-nav a').on('click', function () {
        $(this).parent('li').toggleClass('open');
    });




    $('#filter').change(function(){
        var filter = $('#filter');

    
        $.ajax({
            url:filter.attr('action'),
            data:filter.serialize(), // form data
            type:filter.attr('method'), // POST

            beforeSend:function(xhr){
                filter.find('button').text('Processing...'); 
                $('#response').html('<div class="inner narrow"><h2>Loading...</h2></div>'); 
                
            },
            success:function(data){
                filter.find('button').text('Reset'); 
                $('#response').html(data); 
            }
        });
        return false;
    });


     var $work = $('#work');
     var $switch = $work.find('.radio-switch');

    
    $('.sort').on('change', function() {
        var $val = getSwitchValue();
    
        if ($val == '20') {
            switchRadio();
        }

    });

    
    $switch.on('click', $work, function(e) {
        // e.preventDefault();
        // e.stopPropagation();
        switchRadio();

        var $val = getSwitchValue();

        if ($val == '20') {
             clearFilters();
        }
    

        // callWork();
    });
  
    
    
    function getSwitchValue() {
        return $switch.find('input[type=radio]:checked').val();
    }

    function clearFilters() {
        // $work.find('select').val();

        $("option[value=4]").prop('selected', 'selected').change();
        $("option[value=11]").prop('selected', 'selected').change();
    

        var $val = getSwitchValue();

        if ($val == '24') {
            switchRadio();
        }
  
        
    }
    function clearFeedFilters() {
        $("option[value=all]").prop('selected', 'selected').change();
        $("option[value=selectyear]").prop('selected', 'selected').change();
    }


    $('.reset-feed').on('click', $work, function() {

           clearFeedFilters();

   });
    $('.reset').on('click', $work, function() {
         var $val = getSwitchValue();
         $work.find('select').val();
         if ($val == '24') {
            clearFilters();
        }
        

        // if ($val == '24') {
        //     switchRadio();
        // }

        // callWork();
    });

    function switchRadio() {
        var $radios = $switch.find('input[type=radio]');
        $switch.toggleClass('switched');
        $radios.removeAttr('checked');
            
        if ($switch.hasClass('switched')) {
            $radios.last().prop('checked', true).change();
            
            turnon();
        
        } else {
            $radios.first().prop('checked', true).change();
            turnoff();
        }
    }

    $("a.arrow").click(function(e) {
        e.preventDefault();
        var aid = $(this).attr("href");
        $('html,body').animate({scrollTop: $(aid).offset().top - 198},'slow');
    });

    function turnon(){
        $('#work>div.inner').attr('class','dev-work-group inner narrow').delay( 800 );
        $('#updatelist').attr('class','boxes boxes-4 boxes-square hover').delay( 800 );
        $('#updatelist li').attr('class','dev-work-item').delay( 800 );
    }
    function turnoff(){
        $('#work>div.inner').attr('class','inner narrow');
            $('#updatelist').attr('class','boxes boxes-3 boxes-square featured hover');
            $('#updatelist li').attr('class','none');
    }

    

    function setBackgroundImage(jList) {
        // replace images with background images
        jList.each((_index, element) => {
            const $element = $(element);
            const $inner = $element.find('> .inner-image');
            const $image = $element.find('> img');
            const backgroundImageUrl = `url(${$image.attr('src')})`;
            $inner.css('background-image', backgroundImageUrl);
            $image.hide();
        });
    }

    function preventDefault(e) {
        e = e || window.event;
        if (e.preventDefault)
            e.preventDefault();
        e.returnValue = false;  
    }
      
    function preventDefaultForScrollKeys(e) {
        if (keys[e.keyCode]) {
            preventDefault(e);
            return false;
        }
    }

    function disableScroll() {
        // if (window.addEventListener) // older FF
        //     window.addEventListener('DOMMouseScroll', preventDefault, false);
        document.addEventListener('wheel', preventDefault, {passive: false}); // Disable scrolling in Chrome
        window.onwheel = preventDefault; // modern standard
        window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
        window.ontouchmove  = preventDefault; // mobile
        document.onkeydown  = preventDefaultForScrollKeys;
      }

      function enableScroll() {
        // if (window.removeEventListener)
        //     window.removeEventListener('DOMMouseScroll', preventDefault, false);
        document.removeEventListener('wheel', preventDefault, {passive: false}); // Enable scrolling in Chrome
        window.onmousewheel = document.onmousewheel = null; 
        window.onwheel = null; 
        window.ontouchmove = null;  
        document.onkeydown = null;  
    }


 

var iframe = document.getElementById('large-video-sound');

// $f == Froogaloop
var player = $f(iframe);



// bind events
// $('#sm-play-button').hide();

$("#play-button").click(function(){
    $(".video").toggleClass('hide');
    $(".video-sound").toggleClass('show');
    $("#video-controls").toggleClass('show');
    $(this).toggleClass('hide');
    $('.imagetitle').hide();
    $('#master-video .overlay').hide();
    $('#master-video .arrow').hide();


  });

  $(".stop").click(function(){
    $(".video").toggleClass('hide');
    $(".video-sound").toggleClass('show');
    $("#video-controls").toggleClass('show');
    $('#play-button').toggleClass('hide');
    $('.imagetitle').show();
    $('#master-video .overlay').show();
    $('#master-video .arrow').show();
    $('.play').removeClass('show');
    $('.play').addClass('hide');
    $('.pause').removeClass('hide');
    $('.pause').addClass('show');

  });

  $(".pause").click(function(){
    $(this).removeClass('show');
    $(this).addClass('hide');
    $('.play').removeClass('hide');
    $('.play').addClass('show');

  });
  $(".play").click(function(){
    $(this).removeClass('show');
    $(this).addClass('hide');
    $('.pause').removeClass('hide');
    $('.pause').addClass('show');
  });


//   function setVideoTime(player, seconds) {
// 	player.setCurrentTime(0).then(function() {
// 		return player.stop();
// 	});
// }


var playButton = document.getElementById("play-button");
playButton.addEventListener("click", function() {
  player.api("play");
});

var pauseButton = document.getElementById("pause-button");
pauseButton.addEventListener("click", function() {
    player.api("pause");

});

var smallPlayButton = document.getElementById("sm-play-button");
smallPlayButton.addEventListener("click", function() {
    player.api("play");

});

var stopButton = document.getElementById("stop-button");
stopButton.addEventListener("click", function() {
  player.api("pause");
  player.api('unload');
  
});

// var stopButton = document.getElementsByClassName("stop");
// stopButton.addEventListener("click", function() {
//   player.api("pause");
//   player.api('unload');
  
// });



}(jQuery, document, window));
